import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import If from 'bundles/components/common/If';
import { PaymentOption } from './types';

const Cards: FC<{collection: PaymentOption[] | undefined}> = ({ collection }: {collection: PaymentOption[] | undefined}) => {
  const { t } = useTranslation();

  return (
    <div className="cards-wrapper">
      {(collection || []).map((df) =>
        <div key={df.category} className="payment-option-wrapper">
          <div className="content overflow-hidden">
            <div className={`payment-option ${df.category}`} />
            <div className="payment-option__details">
              <If condition={df.fee_percentage}>
                <p>
                  <span className="details-title">{t('labels.fee')}</span>
                  <strong>{df.fee_percentage}{'%'}
                    <If condition={df.fee_amount}>
                      {' + '}{df.fee_amount}
                    </If>
                  </strong>
                </p>
              </If>
              <p>
                <span className="details-title">{t('labels.limit_min')}</span>
                <strong>{df.limits_minimum}</strong>
              </p>
              <p>
                <span className="details-title">{t('labels.limit_max')}</span>
                <strong>{df.limits_maximum}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
