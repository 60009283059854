import { useEffect, useContext } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import { MerchantRegistrationContext } from 'bundles/contexts/MerchantRegistrationContext/MerchantRegistrationContextProvider';
import axios from 'bundles/apis/defaults';
import { buildFormData } from 'bundles/utils';
import { URL } from 'bundles/constants';
import { useSubmitFormDebounce } from 'bundles/hooks';

const useMerchantRegistrationFirstStep = () => {
  const { state, dispatch } = useContext(MerchantRegistrationContext);
  const { control, register, handleSubmit, formState: { errors, isSubmitted, isSubmitting }, setValue, resetField, getValues, trigger } = useForm<FieldValues>({
    defaultValues: {
      'merchant_registration[authorized_business_representative_attributes][superuser]': 1,
      'step_number': 'step1'
    }
  });

  const { withDebounce } = useSubmitFormDebounce();

  const watchedCountryId = useWatch({ name: 'merchant_registration[authorized_business_representative_attributes][country_of_nationality_id]', control });

  const handleSubmitSignUp = async (next: () => void) => {
    withDebounce(() => handleSubmit(async (f) => {
      axios.post(URL.MERCHANTS_REGISTRATIONS, buildFormData(f))
        .then((response: AxiosResponse) => {
          dispatch({ type: 'ON_NEXT', payload: { ...response.data, currentStep: 2 } });
          next();
        })
        .catch(e => {
          dispatch({
            type: 'ON_BAD_REQUEST', payload: {
              refreshReCaptcha: !state.refreshReCaptcha,
              errorFields: {
                email: {
                  value: getValues('merchant_registration[authorized_business_representative_attributes][email]'),
                  message: e.response.data['merchant_registration[authorized_business_representative_attributes][email]']?.[0]
                }
              }
            }
          });
        });
    })());
  };

  useEffect(() => {
    if(!state.errorFields) return;
    trigger('merchant_registration[authorized_business_representative_attributes][email]', { shouldFocus: true });
  }, [JSON.stringify(state.errorFields)]);

  useEffect(() => {
    if(watchedCountryId) return;
    resetField('merchant_registration[authorized_business_representative_attributes][date_of_birth(3i)]');
    resetField('merchant_registration[authorized_business_representative_attributes][date_of_birth(2i)]');
    resetField('merchant_registration[authorized_business_representative_attributes][date_of_birth(1i)]');
  }, [watchedCountryId]);

  return { register, errors, state, control, setValue, isSubmitting, watchedCountryId, handleSubmitSignUp, isSubmitted };
};

export default useMerchantRegistrationFirstStep;
