import { useEffect, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import { MerchantRegistrationContext } from 'bundles/contexts/MerchantRegistrationContext/MerchantRegistrationContextProvider';
import axios from 'bundles/apis/defaults';
import { buildFormData } from 'bundles/utils';
import { URL } from 'bundles/constants';
import { useSubmitFormDebounce } from 'bundles/hooks';

const useMerchantRegistrationSecondStep = () => {
  const { state, dispatch } = useContext(MerchantRegistrationContext);
  const { register, handleSubmit, formState: { errors, isSubmitting, isSubmitted }, getValues, trigger, setError, clearErrors } = useForm<FieldValues>({
    defaultValues: {
      'merchant_registration[merchant_attributes][accepted_terms_and_conditions]': 1,
      'step_number': 'step2'
    }
  });

  const { withDebounce } = useSubmitFormDebounce();

  const handleChangeCurrency = () => dispatch({ type: 'ON_CHECK_CURRENCY', payload: { checkedCurrencies: getValues('merchant_registration[merchant_attributes][wallets_attributes]').filter((w: Record<'currency_id', string>) => Boolean(w.currency_id)) } });

  const handleChangeCompanyLegalStatus = (label: string) => dispatch({ type: 'ON_SELECT_COMPANY_LEGAL_STATUS', payload: { currentCompanyLegalStatus: label } });

  const handleSubmitSignUp = async (next: () => void) => {
    withDebounce(() => handleSubmit(async (f) => {
      axios.put(`${URL.MERCHANTS_REGISTRATIONS}/${state.merchant_registration_update.id}`, buildFormData(f))
        .then((response: AxiosResponse) => {
          dispatch({ type: 'ON_NEXT', payload: response.data });
          next();
        })
        .catch(e => {
          dispatch({
            type: 'ON_BAD_REQUEST', payload: {
              errorFields: {
                registration_number: {
                  value: getValues('merchant_registration[merchant_attributes][registration_number]'),
                  message: e.response.data['merchant_registration[merchant_attributes][registration_number]'][0]
                }
              }
            }
          });
          trigger('merchant_registration[merchant_attributes][registration_number]', { shouldFocus: true });
        });
    }
    )());
  };

  useEffect(() => {
    if(!isSubmitted) return;
    state.checkedCurrencies?.length ? clearErrors('merchant_registration[merchant_attributes][wallets_attributes]') : setError('merchant_registration[merchant_attributes][wallets_attributes]', { type: 'isRequired' });
  }, [state.checkedCurrencies, isSubmitting]);

  return { register, errors, state, isSubmitting, handleSubmitSignUp, handleChangeCompanyLegalStatus, handleChangeCurrency };
};

export default useMerchantRegistrationSecondStep;
