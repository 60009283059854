import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import { SelectFormGroup } from 'bundles/components/common/Form';
import Billboard from 'bundles/components/common/public/Billboard';
import RegisterBanner from 'bundles/components/common/public/RegisterBanner';
import { URL } from 'bundles/constants';
import axios from 'bundles/apis/defaults';
import Cards from 'bundles/components/public/feesAndLimits/Cards';
import { StateInterface } from 'bundles/components/public/feesAndLimits/types';

const FeesAndLimitsView = () => {
  const { t } = useTranslation();
  const { register: register, handleSubmit, setValue } = useForm();
  const [state, setState] = useState<Partial<StateInterface>>({});

  const withoutAllOptionFor = (options: StateInterface['countries']) => {
    if (!options) return undefined;

    const new_options = [...options];

    new_options?.shift();

    return new_options;
  };

  const handleChangeDepositFundsSelect = () => handleSubmit(f =>
    axios.get(URL.FEES_LIMITS, { params: f }).then((response) => setState(response.data))
  )();

  const handleChangeWithdrawFundsSelect = () => handleSubmit(f =>
    axios.get(URL.FEES_LIMITS, { params: f }).then((response) => setState(response.data))
  )();

  useEffect(() => { axios.get(URL.FEES_LIMITS).then((response) => setState(response.data)); }, []);

  useEffect(() => {
    if (!state.query_params) return;

    setValue('currency_id', state.query_params.currency_id);
    setValue('country_id', state.query_params.country_id);
    setValue('withdrawal_country_id', state.query_params.withdrawal_country_id);
  }, [state.query_params]);

  return (
    <Container fluid>
      <Form>
        <Row>
          <Col sm={12} className="p-0">
            <Billboard title={t('public.fees_and_limits.title')} subTitle={t('public.fees_and_limits.sub_title')} className="billboard-with-content-padding-bottom" />

            <Container>
              <section>
                <h2 className="mw-100 mb-5">
                  {t('public.fees_and_limits.deposit_funds.title')}
                  <span>{t('public.fees_and_limits.deposit_funds.sub_title')}</span>
                </h2>

                <Row className="justify-content-md-between">
                  <Col md={4}>
                    <SelectFormGroup
                      name="currency_id"
                      label={t('labels.currency')}
                      register={register}
                      withSelectOption={false}
                      onChange={handleChangeDepositFundsSelect}
                      options={state.currencies}
                    />
                    <SelectFormGroup
                      name="country_id"
                      label={t('labels.country')}
                      register={register}
                      withSelectOption={false}
                      onChange={handleChangeDepositFundsSelect}
                      options={withoutAllOptionFor(state.countries)}
                    />
                  </Col>
                  <Col md={7}>
                    <Cards collection={state.deposit_funds} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-3 text-primary" size="xs" />
                    <small className="text-gray">
                      {t('public.fees_and_limits.cash_advance_disclaimer')}
                    </small>
                  </Col>
                </Row>
              </section>

              <section>
                <h2 className="mw-100 mb-5">
                  {t('public.fees_and_limits.withdraw_funds.title')}
                  <span>{t('public.fees_and_limits.withdraw_funds.sub_title')}</span>
                </h2>

                <Row className="justify-content-md-between">
                  <Col md={4}>
                    <SelectFormGroup
                      name="withdrawal_country_id"
                      label={t('labels.country')}
                      register={register}
                      withSelectOption={false}
                      onChange={handleChangeWithdrawFundsSelect}
                      options={withoutAllOptionFor(state.countries)}
                    />
                  </Col>
                  <Col md={7}>
                    <Cards collection={state.withdraw_to_bank_funds} />
                  </Col>
                </Row>
              </section>

              <section>
                <RegisterBanner subTitle={t('public.titles.banner.better_way_to_pay.sub_title.fees_and_limits')} />
              </section>
            </Container>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const FeesAndLimitsViewWrapper: FC = () => <FeesAndLimitsView />;

export default FeesAndLimitsViewWrapper;
